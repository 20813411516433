export const isTalentProfile = (path: string) => {
    if (path[0] !== '/') {
        throw Error('Invalid path');
    }

    return path.split('/')[1] === 'tp';
};

export const isVacancyProfile = (path: string) => {
    if (path[0] !== '/') {
        throw Error('Invalid path');
    }

    return path.split('/')[1] === 'vacancy-profiles';
};
