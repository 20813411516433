import { Stack, styled } from '@mui/material';

import { mobileStyles } from 'types/displayTypeStyles';

export const SContainer = styled(Stack)({
    marginTop: '60px',
    gap: '60px',
    textAlign: 'center',

    [mobileStyles]: {
        gap: '45px',
        padding: '40px 12px 0 12px',
    },
});

export const SActionsContainer = styled(Stack)({
    gap: '16px',

    [mobileStyles]: {
        gap: '11px',
    },
});
