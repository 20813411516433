import { useRouter } from 'next/router';

import { basePageWrap } from 'containers/BasePage';
import { isTalentProfile, isVacancyProfile } from 'utils/urlUtils';

import EntityNotFound from 'components/EntityNotFound';
import { EntityNotFoundType } from 'components/EntityNotFound/EntityNotFound';
import NotFound from 'components/NotFound';

const NotFoundPage = () => {
    const { asPath } = useRouter();
    const isTalent = isTalentProfile(asPath);
    const isVacancy = isVacancyProfile(asPath);

    if (isVacancy || isTalent) {
        return (
            <EntityNotFound
                entityType={
                    isTalent
                        ? EntityNotFoundType.TalentProfile
                        : EntityNotFoundType.VacancyProfile
                }
            />
        );
    }

    return <NotFound />;
};

export default basePageWrap(NotFoundPage);
