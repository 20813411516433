export const entityNotFoundContents = {
    vacancyProfile: {
        title: 'Unfortunately this vacancy is no longer available',
        imgSrc: '/img/searchVacancy.png',
        helpingText: 'View our other open vacancies here:',
        url: '/search/?type=vacancies',
        btnTitle: 'Search vacancies',
    },
    talentProfile: {
        title: 'Unfortunately this professional is no longer available',
        imgSrc: '/img/searchProfessional.png',
        helpingText: 'Search professionals that match your requirements here:',
        url: '/search/?type=professionals',
        btnTitle: 'Search professionals',
    },
};
